import React from 'react'
import { COLOR_CONSTANTS } from 'theme'
import App from 'App'
import SEO from 'components/SEO'
import { H1 } from 'components/Typography'
import { Box, Flex } from 'components/Layout'
import BlockOfTextWithTableOfContents from 'components/BlockOfTextWithTableOfContents'
import Footer from 'components/Footer'

const content = [
  {
    name: 'reliability',
    title: 'Reliability & availability',
    body: `
    Vista Social works hard to minimize impacts and downtime. We design and build our systems and applications for fault tolerance, and our team is trained for fast incident recovery. Vista Social attempts to avoid downtime at all costs, unplanned or planned. Our maintenance effort, for the most part, require no downtime, except for most unavoidable situations which are quite rare. Business continuity and disaster recovery processes are built into our practices and our systems.

    <ul>
          <li><strong>A proven track-record</strong> 99.99% uptime is a key performance indicator (KPI) for our Engineering group. At the time of writing, we have had higher than 99.95% uptime over the prior 12 months.</li>
          <li><strong>Isolation</strong> Our highly distributed system uses isolation design patterns to mitigate risks across components. Failures of one system component almost never affect other components.</li>
          <li><strong>Transparency to customers</strong>. Trust begins with open communication. We publicly share real-time system status and metrics on our status page, <a href="https://vistasocial.freshstatus.io/">https://vistasocial.freshstatus.io/</a> for Vista Social. There we communicate incidents and planned maintenance, including any customer impact, and we display system health metrics sourced from independent third-party providers. Customers may subscribe to receive immediate SMS or email notifications of future incidents. </li>
          <li><strong>Recovery point objectives (RPOs)</strong>. Recovery strategies are designed to provide up-to-date RPOs at low Recovery Time Objectives (RTOs), with older data recovered against longer RTOs. This is consistent with customer expectations, enabling customers to meet the immediate needs of their customers.</li>
          <li><strong>Social media feeds</strong>. Our data processing layer combines multiple connections to social network APIs. Being fully approved on social networks like Facebook, Twitter, Instagram and LinkedIn, Vista Social is able to have higher levels of redundancy and access to their support teams.</li>
          <li><strong>DevOps best practices</strong>. Our engineering team practices Infrastructure-as-code, providing correctness, consistency, testability, and speed to recovery. All 24/7/365 on-call team members are empowered to rebuild systems and topologies with full consistency. In the event of system loss, our Engineering team quickly recreates systems by executing the infrastructure code.</li>
          <li><strong>Backups</strong>. Backups are taken frequently, encrypted in transit and at rest, and are tested regularly. Backups are kept "off-site" in Amazon S3 which stores files on multiple physical devices in multiple facilities offering 99.999999999% durability and 99.99% availability.</li>
          <li><strong>Monitoring & on-call support</strong>. We monitor continuously from around the world, displaying, alerting, and reporting upon our entire technical environments in real-time. Supporting customers is a collaboration between our customer-facing support team and our engineering team. Specialized engineers are on call 24/7/365.</li>
    </ul>

    `,
  },
  {
    name: 'datacenters',
    title: 'Data centers',
    body: `
    Vista Social’s products are hosted by Amazon Web Services (AWS). AWS provides world-class hosting facilities that are secure, highly available, and redundant, with compliance to Cloud Security Alliance Star Level 2, ISO 9001, 27001, 27017, 27018, PCI DSS Level 1, and SOC 1, 2, and 3. For more information on AWS's certifications and compliance programs, please visit https://aws.amazon.com/compliance/programs.

    <ul>
      <li><strong>Data location</strong>. Customer data is hosted in the United States, in AWS’s us-east-1.</li>
      <li><strong>Facilities</strong>. AWS's data centers are outfitted with world-class physical hosting capabilities. Buildings have temperature and humidity monitoring and management, automatic water detection and removal, and automatic fire detection and suppression. Combinations of multiple power feeds, Uninterruptible Power Supply (UPS) systems, and on-site electrical generators provide layers of backup power. Telecommunications and Internet connections are redundant. There are no product dependencies on Vista Social corporate offices or other facilities we manage.</li>
      <li><strong>IT security</strong>. Additional security is applied to information technology rooms and systems including forced open door alarms, thread and electronic intrusion detection systems, multi-factor authentication, and media destruction per NIST 800-88.</li>
      <li><strong>Physical security</strong>. Data Center buildings have strict physical access review and scrutiny. All physical access is monitored 24/7 by personnel. Multi-factor authentication is required for all visitors. Continuous monitoring for unauthorized access is done through video surveillance, intrusion detection, and access log monitoring systems.</li>
    </ul>
      `,
  },
  {
    name: 'infrastructure',
    title: `Infrastructure & network security`,
    body: `
    Vista Social employs a dedicated security team. All systems are monitored and alerted 24/7/365 for security and operational events. Host-based Intrusion Detection Systems (IDS) are deployed on all production systems.

    <ul>
    <li><strong>Network controls</strong>. Our private network is segmented into multiple security zones. These bring increasing levels of control, in proximity to customer data.</li>
    <li><strong>Incident management & response</strong>. Vista Social's incident response planning and procedures are based on NIST standards. All incident reports are promptly investigated, reported and remediated as necessary. The response plan and procedures define all the steps to ensure a consistent process.</li>
    <li><strong>Scanning</strong>. Systems and applications are scanned regularly for common vulnerabilities.</li>
    <li><strong>Encryption at rest & in transit</strong>. All communications over public networks with Vista Social’s application and API utilize HTTPS with TLS 1.2 or higher enforced. All data is stored encrypted-at-rest with AES-256 or greater, including backups.</li>
    <li><strong>System administration</strong>. Best practices are utilized, such as least privilege, central configuration management, and stringent host and network firewall policies. Servers are patched automatically on a regular schedule, with high-priority patches applied manually out-of-cycle.</li>
  </ul>

     `,
  },
  {
    name: 'application',
    title: 'Application security',
    body: `
    Vista Social's developers are given annual training on secure coding. All application code is written by Vista Social Employees, and each change undergoes peer review. Security vulnerabilities are promptly triaged and corrected.
      <ul>
        <li><strong>Third-party penetration testing</strong>. Vista Social contracts with multiple penetration testing vendors to conduct several tests per year. Reports are available upon request by customers under NDA.</li>
        <li><strong>DDoS mitigation</strong>. Distributed Denial of Service mitigation is provided via our hosting platform.</li>
        <li><strong>Responsible disclosure policy</strong>. Security researchers may report vulnerabilities by contacting our support team.</li>
      </ul>
      `,
  },
  {
    name: 'team',
    title: 'Employees & internal IT',
    body: `
    Vista Social invests time to train developers how to write secure coding, and all employees participate receive annual general security and data privacy training. Phishing drills are routinely administered, and measured against industry benchmarks.
     <ul>
      <li><strong>Information security policies & standards</strong>. Vista Social has a comprehensive set of policies and standards covering all aspects of security and privacy. All Employees must affirm their responsibilities in protecting customer data as part of their condition of employment.</li>
      <li><strong>Secure support protocols</strong>. Our world-class Support team follows phishing and threat-resistant protocols designed by our Security team, when conducting sensitive actions on customer accounts.</li>
      <li><strong>Offices</strong>. Vista Social offices are secured by keycard access. Office networks are segmented, centrally monitored, and protected by firewalls and Intrusion Prevention devices. Our products have no dependencies on our company’s offices or other facilities we manage.</li>
      <li><strong>Devices</strong>. All Vista Social devices are inventoried with asset tags and managed with a central mobile device management (MDM) solution.</li>
      <li><strong>Endpoints</strong>. Employee workstations are secured with hard drive encryption, Antivirus and advanced malware detection with central management and control.</li>
      <li><strong>Background checks</strong>. All new hires with access to customer data undergo a criminal history and background check prior to employment.</li>
      <li><strong>Business continuity</strong>. Like the hosting of our products, while Vista Social maintains physical offices around the world, the continued operation of our business is not dependent on these offices. Our products, customer service, and overall business operations are enabled to carry on uninterrupted by physical incidents or issues at our offices. During the COVID-19 (Coronavirus) pandemic, Vista Social transitioned to an all-remote workforce without delay or interruption, ensuring continuity of services to our customers. Our team is equipped with Cloud-based tools and remote access & collaboration solutions, and makes use of these tools daily.</li>
      
     </ul>     
      `,
  },
  {
    name: 'features',
    title: 'Product security features',
    body: `
     <ul>
      <li><strong>Multi-factor authentication (MFA)</strong>. Account owners and administrators may require that their users leverage this additional security layer. Vista Social supports apps like Google Authenticator and others that implement the Time-based One-time Password Algorithm (TOTP) or HMAC-based One-time Password Algorithm (HOTP) for generating passcodes.</li>
      <li><strong>Secure credential storage</strong>. </li>
      <li><strong>Brute-force protections</strong>. In addition to computationally challenging hashing, our authentication services implement additional rate-limiting protections and ReCAPTCHA.</li>
      <li><strong>Approval workflows</strong>. Account Owners and Administrators may restrict certain activities behind approval workflows. These allow for tasks to be divided amongst a team, with the peace of mind that central decision makers may review and control public-facing actions.</li>
      <li><strong>IP restrictions</strong>. Vista Social may be configured to restrict application and API access from specific IP ranges.</li>
      <li><strong>Email signing</strong>. Vista Social implements Sender Policy Framework (SPF) and DomainKeys Identified Mail (DKIM) to ensure emails we send are authenticated as coming from Vista Social, helping to prevent spoofing and ensure authenticity.</li>
      <li><strong>Access permissions</strong>. Account Owners and Administrators may restrict access to profiles, features, actions (including read and write), and other data, by applying granular controls to users on their account.</li>
     </ul>     
      `,
  },
  {
    name: 'certs',
    title: 'Compliance & certifications',
    body: `
    <ul>
      <li><strong>SOC 2 Type 2</strong>. Vista Social regularly completes a SOC 2 Type 2 audit by a qualified, third-party auditor to examine our information systems relevant to security in accordance with the <a href="https://us.aicpa.org/research/standards/auditattest/ssae">AICPA’s Statement on Standard for Attestation Engagements No. 18 (SSAE 18)</a>.</li>
      <li><strong>CSA STAR</strong>. Vista Social aligns its security program, in part, with the Cloud Controls Matrix framework offered by the Cloud Security Alliance (CSA). Vista Social has completed a Level 1 assessment through the CSA’s Security Trust Assurance and Risk (STAR) registry.</li>
      <li><strong>Payment Card Industry (PCI)</strong>. Vista Social is PCI DSS compliant through a PCI SAQ A self-assessment. Vista Social entirely outsources its processing of cardholder data to third-party payment processors who are approved by PCI and compliant to PCI DSS  Level 1.</li>
      <li><strong>GDPR and CCPA/CPRA</strong>. Vista Social aligns its privacy program with the General Data Protection Regulation (GDPR) of the European Union and United Kingdom, and the California Consumer Privacy Act (CCPA), as further amended by the California Privacy Rights Act (CPRA).</li>
    </ul>     
     `,
  },
]

const VulnerabilityReportingPolicy = () => (
  <App>
    <SEO
      title="Security | Vista Social"
      description="Learn more about the security at Vista Social"
      path="/security/"
    />
    <Flex mt="xl" flexDirection="column" alignItems="center">
      <H1 color={COLOR_CONSTANTS.DENIM} fontSize="4xl" fontWeight="bold">
        Hosting & security
      </H1>
    </Flex>
    <Box m="0 auto" px="m" mt="xl" maxWidth="1080px">
      <BlockOfTextWithTableOfContents content={content} />
    </Box>
    <Footer />
  </App>
)

export default VulnerabilityReportingPolicy
